import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import {
  FeatureCard,
  FeatureCardContainer,
  FeatureWrapper,
  FeaturesContainer,
  FeaturesHeader,
  FeaturesHeading,
  FeaturesLine,
  FeaturesOrder,
  FeaturesSubHeader,
  FeaturesSubHeading,
  FlipCardBack,
  FlipCardFront,
  FlipCardInner
} from './FeaturesStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';

const featuresData = [
  {
    src: '/icons/responsive-tablet.svg',
    heading: 'Fully Responsive Web Design',
    subHeading: 'Our mobile-first code architecture ensures your site delivers exceptional performance on any device. Experience seamless functionality and stunning visuals on any desired device.'
  },
  {
    src: '/icons/security.svg',
    heading: 'Security First',
    subHeading: 'Our top-tier security protocols safeguard your website, protecting your data and providing peace of mind.'
  },
  {
    src: '/icons/web-design.svg',
    heading: 'Stunning Design',
    subHeading: "Our skilled designers craft visually stunning websites that align with your brand identity and deliver exceptional user experiences."
  },
  {
    src: '/icons/seo-1.svg',
    heading: 'SEO Friendly',
    subHeading: 'Our SEO-friendly services ensure your website ranks higher, driving more traffic and delivering measurable value to your business'
  },
  {
    src: '/icons/edit.svg',
    heading: 'Easy Editing',
    subHeading: "At Planktor, our user-friendly CMS empowers you to effortlessly update and manage your website's content."
  },
  {
    src: '/icons/employees.svg',
    heading: 'Experienced Employees',
    subHeading: "At Planktor, we offer expanded services through trusted partners to meet your unique needs."
  }
];

const Features = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  

  return (
    <Box pt='112px' mx='auto' maxWidth='1360px' id='features'>
      <FeaturesHeader>Why Choose Planktor?</FeaturesHeader>
      <FeaturesSubHeader mt='40px'>
        Our work ethic and moral character are good starting points,
      </FeaturesSubHeader>
      <FeaturesSubHeader mb='40px'>
        but here are a few technical reasons too:
      </FeaturesSubHeader>
      <FeaturesLine />
      <FeatureCardContainer mt={3}>
      {featuresData.map((feature, index) => (
          <FeatureCard key={index}>
            <FlipCardInner className="flip-card-inner">
              <FlipCardFront>
                <img src={feature.src} width='80px' height='80px' alt={feature.heading} />
               {feature.heading}
              </FlipCardFront>
              <FlipCardBack>
                <p>{feature.subHeading}</p>
              </FlipCardBack>
            </FlipCardInner>
          </FeatureCard>
        ))}

      </FeatureCardContainer>
    </Box>
  );
};

export default Features;
